<template>
    <div class="container">
        <div class="tab-list">
            <div v-for="(item,index) in tabList" 
            :key="index" 
            :class="['tab-item', curIndex == index ? 'active' : '']" 
            @click="changeCurIndex(index)">
                {{item}}
            </div>
        </div>
        <el-form v-show="curIndex == 0" :model="loginForm" :rules="rules" ref="loginForm" label-position="top" :hide-required-asterisk="true" class="form">
            <el-form-item label="Email" prop="phone">
                <el-input v-model="loginForm.phone" placeholder="Please enter your email"></el-input>
            </el-form-item>
            <el-form-item label="Password" prop="password">
                <el-input v-model="loginForm.password" show-password placeholder="Please enter your password"></el-input>
            </el-form-item>
            <el-form-item>
                <div class="submit-btn" @click="login">Login</div>
            </el-form-item>
        </el-form>
        <el-form v-show="curIndex == 1" :model="registerForm" :rules="rules" ref="registerForm" label-position="top" :hide-required-asterisk="true" class="form">
            <el-form-item label="Email" prop="phone">
                <el-input v-model="registerForm.phone" placeholder="Please enter your email"></el-input>
            </el-form-item>
            <el-form-item label="Password" prop="password">
                <el-input v-model="registerForm.password" show-password placeholder="Please enter your password"></el-input>
            </el-form-item>
            <el-form-item label="Password Again" prop="confirmPassword">
                <el-input v-model="registerForm.confirmPassword" show-password placeholder="Please enter password again"></el-input>
            </el-form-item>
            <!-- <el-form-item label="Code" prop="captcha">
                <div class="code-box">
                    <el-input v-model="registerForm.captcha" placeholder="Please enter verification code"></el-input>
                    <img class="code-img" :src="captchaImg" @click="getCaptcha" />
                </div>
            </el-form-item> -->
			<el-form-item label="Code" prop="code">
			    <div class="code-box">
			        <el-input v-model="registerForm.code" placeholder="Please enter verification code"></el-input>
			        <div v-if="!isSend" class="send-btn" @click="sendCode">Send</div>
					<div v-else class="send-btn disabled-btn">{{second}}s</div>
			    </div>
			</el-form-item>
            <el-form-item>
                <div class="submit-btn" @click="register">Create My Account</div>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    export default {
        data() {
            const checkPassword = (rule, value, callback) => {
                if (!value) {
                    callback(new Error('Please enter password again'))
                } else if (value != this.registerForm.password) {
                    callback(new Error('The passwords entered twice are inconsistent'))
                } else {
                    callback()
                }
            }
            return {
                tabList: ['Login', 'Register'],
                curIndex: 0,
                loginForm: {
                    phone: '',
                    password: ''
                },
                registerForm: {
                    phone: '',
                    password: '',
                    confirmPassword: '',
					code: ''
                    // captcha: '',
                    // key: ''
                },
                rules: {
                    phone: [
                        { required: true, message: 'Please enter your email', trigger: 'blur' },
                        { type: 'email',  message:  'Please enter the correct email format', trigger: 'submit' }
                    ],
                    password: [
                        { required: true, message: 'Please enter your password', trigger: 'blur' },
                        { min: 6, message: 'Password at least 6 characters', trigger: 'blur' }
                    ],
                    confirmPassword: [
                        { validator: checkPassword, trigger: 'blur' }
                    ],
                    // captcha: [
                    //     { required: true, message: 'Please enter verification code', trigger: 'blur' }
                    // ]
					code: [
					    { required: true, message: 'Please enter verification code', trigger: 'blur' }
					]
                },
                captchaImg: '',
				isSend: false,
				second: 120,
				timer: null
            }
        },
        created() {
            // this.getCaptcha()
			clearInterval(this.timer)
        },
        watch: {
            curIndex() {
                this.$refs.loginForm.resetFields()
                this.$refs.registerForm.resetFields()
            }
        },
        methods: {
            changeCurIndex(index) {
                this.curIndex = index
            },
            
            getCaptcha() {
                this.$api.captcha().then(res => {
                    this.captchaImg = res.data.img
                    this.registerForm.key = res.data.key
                })
            },
			
			sendCode() {
			    if(!this.registerForm.phone) {
					this.$message({
					    message: 'Please enter your email',
					    type: 'warning'
					})
			    } else {
			    	this.$api.sendSms({
			    		account: this.registerForm.phone
			    	},true).then(res => {
						this.isSend = true
						this.timer = setInterval(() => {
							this.second--
							if (this.second == 0) {
								this.second = 120
								this.isSend = false
								clearInterval(this.timer)
							}
						}, 1000)
						this.$message({
						    message: res.message,
						    type: 'success'
						})
					})
			    }
			},
            
            login() {
                this.$refs.loginForm.validate((valid) => {
                    if(valid) {
                        this.$api.login(this.loginForm,true).then(res => {
                            this.$store.commit('setToken',res.data.token)
                            this.$store.dispatch('getCartList')
                            this.$router.push({
                                path: '/'
                            })
                            this.$message({
                                message: res.message,
                                type: 'success'
                            })
                        })
                    }
                })
            },
            
            register() {
                this.$refs.registerForm.validate((valid) => {
                    if(valid) {
                        this.$api.register(this.registerForm,true).then(res => {
                            this.$message({
                                message: res.message,
                                type: 'success',
                                duration: 1500,
                                onClose: () => {
                                    this.curIndex = 0
                                }
                            })
                        }).catch(err => {
                            // console.log(err)
                            // this.getCaptcha()
                        })
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    // PC
    @media only screen and (min-width: 1024px) {
        .container {
            width: 50rem;
            box-sizing: border-box;
            padding: 4rem 0;
            margin: 0 auto;
            
            .tab-list {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                margin-bottom: 3rem;
                
                .tab-item {
                    position: relative;
                    height: 4rem;
                    line-height: 4rem;
                    box-sizing: border-box;
                    padding: 0 3rem;
                    font-size: 1.8rem;
                    color: #999999;
                    text-align: center;
                    white-space: nowrap;
                    cursor: pointer;
                    
                    &:not(:last-child)::after {
                        position: absolute;
                        top: 50%;
                        right: 0;
                        transform: translateY(-50%);
                        content: '';
                        width: 1px;
                        height: 2.4rem;
                        background-color: #969494;
                    }
                }
                
                .active {
                    color: #333333;
                }
            }
            
            .form {
                width: 100%;
                
                /deep/ .el-form-item {
                    margin-bottom: 2.5rem;
                }
                
                .code-box {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    
                    .code-img {
                        min-width: 12rem;
                        max-width: 12rem;
                        height: 4rem;
                        margin-left: 2rem;
                        cursor: pointer;
                    }
					
					.send-btn {
						display: flex;
						justify-content: center;
						align-items: center;
						min-width: 10rem;
						max-width: 10rem;
						height: 4rem;
						box-sizing: border-box;
						margin-left: 2rem;
						border-radius: 4px;
						font-size: 1.4rem;
						color: #FFFFFF;
						background-color: #333333;
						cursor: pointer;
					}
					
					.disabled-btn {
						background-color: #CCCCCC;
					}
                }
                
                .submit-btn {
                    width: 100%;
                    height: 4.6rem;
                    line-height: 4.6rem;
                    box-sizing: border-box;
                    margin-top: 1rem;
                    border-radius: 4px;
                    font-size: 1.4rem;
                    color: #FFFFFF;
                    text-align: center;
                    white-space: nowrap;
                    background-color: #333333;
                    cursor: pointer;
                }
            }
        }
    }
    
    // 手机
    @media only screen and (max-width: 1024px) {
        .container {
            width: 100%;
            box-sizing: border-box;
            padding: 2rem $h5-box-padding 3rem;
            margin: 0 auto;
            
            .tab-list {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                margin-bottom: 2.5rem;
                
                .tab-item {
                    position: relative;
                    height: 4rem;
                    line-height: 4rem;
                    box-sizing: border-box;
                    padding: 0 2.5rem;
                    font-size: 1.8rem;
                    color: #999999;
                    text-align: center;
                    white-space: nowrap;
                    cursor: pointer;
                    
                    &:not(:last-child)::after {
                        position: absolute;
                        top: 50%;
                        right: 0;
                        transform: translateY(-50%);
                        content: '';
                        width: 1px;
                        height: 2.4rem;
                        background-color: #969494;
                    }
                }
                
                .active {
                    color: #333333;
                }
            }
            
            .form {
                width: 100%;
                
                /deep/ .el-form-item {
                    margin-bottom: 2.4rem;
                }
                
                .code-box {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    
                    .code-img {
                        min-width: 12rem;
                        max-width: 12rem;
                        height: 4rem;
                        margin-left: 1.5rem;
                        cursor: pointer;
                    }
					
					.send-btn {
						display: flex;
						justify-content: center;
						align-items: center;
						min-width: 8rem;
						max-width: 8rem;
						height: 4rem;
						box-sizing: border-box;
						margin-left: 1.2rem;
						border-radius: 4px;
						font-size: 1.4rem;
						color: #FFFFFF;
						background-color: #333333;
						cursor: pointer;
					}
					
					.disabled-btn {
						background-color: #CCCCCC;
					}
                }
                
                .submit-btn {
                    width: 100%;
                    height: 4.6rem;
                    line-height: 4.6rem;
                    box-sizing: border-box;
                    margin-top: 1rem;
                    border-radius: 4px;
                    font-size: 1.4rem;
                    color: #FFFFFF;
                    text-align: center;
                    white-space: nowrap;
                    background-color: #333333;
                    cursor: pointer;
                }
            }
        }
    }
</style>